//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-8:_8944,_5676,_3792,_3372,_5660,_4204,_7752,_7360;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-8')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-8', "_8944,_5676,_3792,_3372,_5660,_4204,_7752,_7360");
        }
      }catch (ex) {
        console.error(ex);
      }