
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function subtitlesRT () {
    function repeatHierarchicalGroups1(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                return _createElement('div', { 'className': 'cm_vehicle-categories_category-wrapper' }, _createElement('a', {
                    'className': 'cm_vehicle-categories_category-container cm_vehicle-categories_link',
                    'href': this.href
                }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container cm_vehicle-categories_link' }, _createElement('img', {
                    'className': 'cm_vehicle-categories_category-image',
                    'src': this.getImageOrDefault(this.imageUrl),
                    'alt': this.value
                })), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container cm_vehicle-categories_link' }, _createElement('h5', { 'className': 'cm_vehicle-categories_category-title' }, this.value))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-categories cm_vehicle-categories__subcategories cm_vehicle-categories__flat' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-categories_category-wrapper-repeater cm_vehicle-categories_category-wrapper-repeater cmRepeater_hierarchicalGroups' },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups1.bind(this))
    ]));
}
        export const componentNames = []